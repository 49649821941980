import { useEffect, useState } from 'react'

import { useIdentity } from '@/utils/passport/withIdentity'

// types
import type { PermissionData } from '@/components/UI/comments/commentsCard/types'
import type { UsePermissionProp } from '@/utils/types'

export const usePermissions = (
  { project, module, feature }: UsePermissionProp = {
    project: {},
    module: {},
    feature: {},
  }
): PermissionData => {
  const user = useIdentity()
  const [editable, setEditable] = useState(false)
  const [show, setShow] = useState(false)
  const [permissions, setPermissions] = useState()

  const projectId = project?.id
  const smallProject = project?.small
  const region = project?.region?.name

  useEffect(() => {
    if (typeof user?.permissions === 'function') {
      const { show, edit, permissions } = user?.permissions({
        module,
        feature,
        region,
        projectId,
        smallProject,
      })

      setEditable(edit)
      setShow(show)
      setPermissions(permissions)
    }
  }, [feature, module, projectId, region, smallProject, user])

  return { editable, show, permissions, user }
}

export default usePermissions
