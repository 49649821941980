import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  textarea.custom, select, input, .custom-select, .form-control {
    &:focus {
      outline: none;
      border: 1px solid ${({ theme }) => theme.primary};
      box-shadow: 0 0 5px 0 ${({ theme }) => theme.primary};
    }
  }
  a{

    text-decoration-color: ${({ theme }) => theme.primary};
  }
  * {
    scrollbar-width: thin;
    scrollbar-color: #d8d8d8 #ededed;
  }
  *::-webkit-scrollbar {
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    background: #ededed;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 20px;
    border: 2px solid #ededed;
  }
`

export default GlobalStyle
